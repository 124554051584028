*
{
    margin: 0;
    padding: 0;
}


body {
    background-color: #eee6db;
    font-family: mundial, sans-serif;
    color: #1b4953;
    font-size: 1.1vw;
    line-height: 1;
    overflow: hidden;
    position: relative;
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

.webgl
{
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    
}

#webgl {
    position: relative;
    cursor: move;
    z-index: 1;
    border-style: solid;
    border-color: #ec2127;
    border-width: 0em;
}

.title {
    position: fixed;
    width: 20em;
    left: 50%;
    top: 1em;
    right: auto;
    bottom: auto;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: mundial, sans-serif;
    color: white;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    transform: translateX(-50%);
    margin: 0 0 0 0;
    transition: 3s opacity;
    transition-timing-function: cubic-bezier(.5, 0, .56, 1);
    opacity: 1;
    
}

.title-subtext {
    display: flex;
    align-content: center;
    align-self: center;
    background-color: #45b1c8;
    border-style: solid;
    border-color: #45b1c8;
    border-width: 4px;
    border-radius: 1em;
    padding: 1rem;
    box-shadow: 0px 0px 30px -10px #00000075;
    font-weight: 400;
    font-size: 1.25em;
    line-height: normal;
    margin: 3rem 0em 1em 0em;
    max-width: 25em;
    
}
#title {
    width: 40rem;
    margin-top: 2rem;
}
#site-logo {
    transform: translateY(-100%);
}
.title-text-floating {
    font-size: 1.5em;
    color: white;
    font-weight: 700;
    max-width: 15em;
    display: flex;
    align-content: center;
    align-self: center;
    background-color: #45b1c8;
    border-style: solid;
    border-color: #45b1c8;
    border-width: 4px;
    border-radius: 1em;
    padding: 1rem;
    box-shadow: 0px 0px 30px -10px #00000075;

}

.title.fade-out {
    opacity: 0;
}








.section {
    width: 100%;
}

.section.map {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 99;
    overflow: hidden;
}

.container {
    width: 90vw;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
}

.container.full-width {
    width: 100%;
    max-width: none;
}

.section-navigation {
    position: fixed;
    z-index: 999;
    width: 100%;
    margin-bottom: 0px;
}

.navigation-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 6vh;
}

.right-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.left-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.center-col {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}

.body {
    background-color: #eee6db;
}

.navtext-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 1rem 2rem;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.navtext-wrapper.footer {
    padding: 0.5rem 1rem;
    -webkit-transition: all 200ms cubic-bezier(.455, .03, .515, .955);
    transition: all 200ms cubic-bezier(.455, .03, .515, .955);
    color: #ec2127;
}

.navtext-wrapper.footer:hover {
    background-color: #ec2127;
    color: #eee6db;
}

.navtext {
    font-family: mundial, sans-serif;
    font-size: 1.5em;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.navtext.footer {
    font-size: 1em;
}

.nav-textbox {
    margin-right: 0.5em;
    margin-left: 0.5em;
    color: #efdcbf;
    text-decoration: none;
}

.logo-image {
    display: block;
    width: 10em;
}

.html-embed {
    display: none;
}

.site-logo {
    padding: 1em 2em 1.5em;
    border-style: none solid solid;
    border-width: 0.25rem;
    border-color: #45b1c8;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #ffd4b7;
    box-shadow: 0px 0px 30px -10px #00000075;

}

.site-logo.w--current {
    width: auto;
}

.control-wrap {
    position: absolute;
    left: auto;
    top: 7%;
    right: -2px;
    bottom: auto;
    z-index: 900;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: auto;
    padding: 1em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    opacity: 0;
    background-color: #ec2127;
    border-radius: 1em 0em 0em 1em;
    transform: translateX(100%);
    
}

.control-button {
    position: relative;
    z-index: 991;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 5em;
    height: 5em;
    margin-bottom: 0.25rem;
    padding: .7em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    border-radius: 10px;
    background-color: #ffd4b7;
    box-shadow: 0px 0px 30px -10px #00000075;
    cursor: pointer;
    transition: 0.2s all;
    transition-timing-function: cubic-bezier(.5, 0, .56, 1);
    opacity: 1;
}
.control-button:hover {
    background-color: #ffb482;
    color: #eee6db;
}

.div-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.icon {
    width: 100%;
    height: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    opacity: 0;
    cursor: pointer;
}

.control-button-text {
    position: absolute;
    left: auto;
    top: 0%;
    right: 1rem;
    bottom: auto;
    z-index: 990;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-top: 1.25rem;
    padding: 0.25rem 4rem 0.25rem 1rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    border-radius: 10px;
    background-color: #ffd4b7;
    white-space: nowrap;
}

.text-block {
    font-family: mundial, Arial, sans-serif;
    font-size: 1em;
    font-weight: 300;
}

.button {
    position: relative;
    
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: .75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    background-color: #ffd4b7;
    border-radius: .5em;
    font-family: mundial, sans-serif;
    color: #ec2127;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    line-height: 0;
    cursor: pointer;
    transition: 0.2s background-color;
    margin: 0,0,0,0;
    cursor: pointer;
    opacity: 1;
    box-shadow: 0px 0px 30px -10px #00000075;
    transform-origin: center;
}

.button:hover {
    background-color: #ec2127;
    color: #ffd4b7;
}
.button.fade-out {
    opacity: 0 !important;

}
.button-text {
    font-family: mundial, sans-serif;
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
    margin: 0;
}
.label-wrap {
    position: relative;
    padding-right: 1rem;
    padding-left: 1rem;
    width: auto;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    font-family: mundial, sans-serif;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    
    
}

.label {
    position: relative;
    z-index: 1;
    width: 1.75em;
    height: 1.75em;
    
    
    /* border-color: #45b1c8; */
    /* background-color: #ffd4b7; */
    font-family: mundial,
    sans-serif;
    color: #ec2127;
    font-size: 1em;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    
    margin: 0,
    0,
    0,
    0;
    
    transition: 0.3s opacity;
    transition-timing-function: cubic-bezier(.5, 0, .56, 1);
    opacity: 1;
    
}

.icon-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-content: center;
    justify-content: center;
    transform: scale(100%, 100%);
   transition: 0.3s all;
    transition-timing-function: cubic-bezier(.5, 0, .56, 1);
    opacity: .5;
}
.icon-wrap.translate {
    transform: scale(200%, 200%);;
    opacity: .90;
    
}


.label-text-wrap {
    width: fit-content;
    opacity: 0;
    transition: .3s opacity;
    transition-timing-function: cubic-bezier(.5, 0, .56, 1);
    margin-bottom: -1em;
    
}

.label-text-wrap.fade-in {
    
    opacity: 0.5;
}
.time-copy {
    position: fixed;
    left: 1%;
    top: 82%;
    right: auto;
    bottom: auto;
    z-index: 999;
    display: none;
    height: 75px;
    min-width: 100px;
    padding-right: 1rem;
    padding-left: 1rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    background-color: #ffd4b7;
    font-family: mundial, sans-serif;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    white-space: pre-wrap;
}
.captions {
    position: fixed;
    left: auto;
    top: auto;
    right: 0;
    bottom: 1em;
    height: 10em;
    width: 20em;
    max-width: 50%;
    padding: 1em;
    transform: translateY(100%);
    
    color: #ffd4b7;
    background-color: #ec2127;
    font-family: mundial, sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    white-space: pre-wrap;
    z-index: 900;
    opacity: 0;
    display: flex;
    flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
    border-radius: 1em 1em 0em 0em;
    

}
#pin-tooltip {
    
}
    
#default-tooltip{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    opacity: 1;
}
.caption-text {
    font-size: .75em;
    font-weight: 400;
}
.captions-text-wrap {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    
}
.caption-title {
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
    margin: 0;
}
.stats {
    position: fixed;
    left: 1%;
    top: 19%;
    right: auto;
    bottom: auto;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 75px;
    min-width: 100px;
    padding-right: 1rem;
    padding-left: 1rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    background-color: #ffd4b7;
    font-family: mundial, sans-serif;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    white-space: pre-wrap;
}

.paragraph {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: mundial, Arial, sans-serif;
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 1.25px;
    text-transform: none;
}

.section-footer {
    position: fixed;
    left: 3%;
    top: auto;
    right: auto;
    bottom: 3%;
    z-index: 999;
    margin-bottom: 0px;
}

.footer-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 3vh;
    border-style: solid;
    border-width: 2px;
    border-color: #45b1c8;
    border-radius: 0.5rem;
    background-color: #ffd4b7;
}

.center-col-footer {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}

.canvas-wrap {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
}

@media screen and (max-width: 991px) {
    body {
        background-color: #eee6db;
        font-size: 2.5vw;
    }
    .logo-image {
        width: 5em;
    }

    .text-block {
        font-size: 0.8em;
    }
    .button-text {
       font-size: 1em;
       line-height: normal; 
    }
    .button {
        padding: .5rem;
    }
}

@media screen and (max-width: 479px) {
    body {
        font-size: 2.5vw;
    }
    .control-button {
        width: 40px;
        height: 40px;
    }
    .control-wrap {
        right: 0px;
    }
    .title {
        position: fixed;
        width: 90%;
        font-size: .85em;
        
    
    }
    .caption-text {
        font-size: .5em;
        font-weight: 400;
    }
    .captions {
        height: 5em;
        max-width: 95%;
        left: 0;
        border-radius: 0;
    }
    
    .section.map {
        width: 100%;
        overflow: hidden;
    }

}

/* @font-face {
    font-family: 'mundial';
    src: url('../assets/mundial.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'mundial';
    src: url('../mundial-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
} */


/* Loading Screen */

#loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffd4b7;
    opacity: 1;
    transition: 1s opacity;
    z-index: 1000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

#loading-screen.fade-out {
    opacity: 0;
    
}

.loader {
    display: block;
    right: auto;
    bottom: auto;
    z-index: 900;
    
    width: 25%;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    
    opacity: 1;
    transition: 5s opacity;
    transition-timing-function: cubic-bezier(.5, 0, .56, 1);
}

